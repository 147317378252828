import type { BookingSlot } from '~/@types/graphql-police'
import type { LinkProps } from '~/components/Link'
import { ROUTES } from '~/constants/routes'

type SubscriptionRoutes = Pick<
  typeof ROUTES,
  'subscription_booking' | 'subscription_payment' | 'signup'
>
type BookingOrPayment = SubscriptionRoutes[keyof SubscriptionRoutes]

type GetSubscriptionLinkToParams = {
  pathname: BookingOrPayment
  slotId?: BookingSlot['id']
  isInvoiceNeeded?: boolean
}

export function getSubscriptionLinkTo({
  pathname,
  slotId,
  isInvoiceNeeded
}: GetSubscriptionLinkToParams): LinkProps['to'] {
  const searchParams = new URLSearchParams()

  if (slotId) {
    searchParams.append('slotId', slotId)
  }

  if (isInvoiceNeeded) {
    searchParams.append('isInvoiceNeeded', 'true')
  }

  return {
    pathname: pathname.toString(),
    search: searchParams.toString()
  }
}
